import React from 'react'
import { Empty } from 'antd';
export default function Error() {
  return (
    <div style={{width:'100%',height:'100%'}}>
         <Empty
            image="https://images.maxdata.cc/museum/images/23-04-14/f37cef95786ac2489dad26236595565c6438b72790f29.jpg"
            imageStyle={{
            height: '70vh',
            width: '70vh',
            margin:0
            }}
        >
        </Empty>
    </div>
   
  )
}
