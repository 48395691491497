import axios from 'axios'
import {getToken} from './auth'
import {  Modal } from 'antd';
const service = axios.create({
    baseUrl: '/',
    timeout: 50000,
})
service.interceptors.request.use(config=>{
    if(getToken()){
        config.headers['Authorization'] = 'Bearer '+getToken();
    }
    config.headers['Content-Type']='application/json;charset=UTF-8'
    return config
},error=>{
    
    return promise.reject(error)
})

service.interceptors.response.use(response=>{
   
    return response
},error=>{
    
    Modal.error({
        title: '服务器响应错误，请稍后重试！',
      });
})

export default service