import {persistStore, persistReducer} from 'redux-persist';
// import storage from 'redux-persist/lib/storage';

// import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
// const persistConfig = {
//     key: 'root',
//     storage: storage,
//     timeout: 10000,
//     stateReconciler: autoMergeLevel2 // 查看 'Merge Process' 部分的具体情况
// }
import {legacy_createStore as createStore} from 'redux'
// import {redu} from './reducers/redu'
// const reducer= combineReducers({
//     redu
// })
const reducer= (pre={
    isClick:false,
    info:{}
},action)=>{
    
    let newState= {...pre}
    if(action.type=='setinfo'){
        newState.info=action.payload
        return newState
    }
    if(action.type=='lognOut'){
        newState.info={}
        return newState
    }
}
// const myPersistReducer = persistReducer(persistConfig, reducer)
const store = createStore(reducer)
// export const persistor = persistStore(store)
export default store


function zhneStore(reducer){
    let list =[]
    let state=reducer()
    function dispatch(action){
        for(let i in list){
            list[i]&&list[i]()
        }
        state=reducer(state,action)
    }
    function subscribe(callback){
        list.push(callback)
    }
    function getState(){
        return state
    }
    return {
        dispatch,
        subscribe,
        getState
    }
}
// 纯函数  reducer是纯函数
// 1.对外界没有副作用
// 2.同样的输入得到同样的输出