export function getToken(){
    return localStorage.getItem('Token')
}
export function setToken(token){
    localStorage.setItem('Token', token)
}
export function setInfo(info){
    localStorage.setItem('Info', JSON.stringify(info))
}
export function getInfo(){
    let info = localStorage.getItem('Info')
    return JSON.parse(info)
}
export function removeToken(){
    localStorage.removeItem('Token')
}
export function removeInfo(){
    localStorage.removeItem('Info')
}