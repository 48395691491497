import React from 'react';
import {BrowserRouter,Route,Switch,Redirect} from 'react-router-dom'
import Login from '../pages/login/index'
import Layout from '../pages/layout/index'
import Error from '../pages/error/error'

export default function router() {
  return (
    <BrowserRouter>
        <Switch>
            <Route path='/layout' render={()=>
                localStorage.getItem('Token')?<Layout></Layout>:
                <Redirect to='/' />
            }></Route>
            <Route path='/' component={Login} exact></Route>
            <Route path='/error' component={Error} ></Route>
            <Redirect from='/' to='/error' />
        </Switch>
    </BrowserRouter>
  )
}
