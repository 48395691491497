import { lazy } from 'react'
const Routes = [
    {
        path: '/layout/ticket/category',
        comment: lazy(() => import('../ticket/category/category'))
    },
    {
        path: '/layout/ticket/addTicket',
        comment: lazy(() => import('../ticket/addTicket/addTicket'))
    },
    {
        path: '/layout/ticket/list',
        comment: lazy(() => import('../ticket/list/list'))
    },
    {
        path: '/layout/travel/travel',
        comment: lazy(() => import('../travel/travel'))
    },
    {
        path: '/layout/sellticket/sellticket',
        comment: lazy(() => import('../sellticket/sellticket'))
    },
    {
        path: '/layout/subscribe/appointment',
        comment: lazy(() => import('../subscribe/appointment/appointment'))
    },
    {
        path: '/layout/subscribe/reservationsetting',
        comment: lazy(() => import('../subscribe/reservationsetting/reservationsetting'))
    },
    {
        path: '/layout/statistics/statistics',
        comment: lazy(() => import('../statistics/statistics'))
    },
    {
        path: '/layout/wineshopTicket/wineshopTicket',
        comment: lazy(() => import('../wineshopTicket/wineshopTicket'))
    },
    {
        path: '/layout/wineshop/wineshop',
        comment: lazy(() => import('../wineshop/wineshop'))
    },
    {
        path: '/layout/creativity/addCreativity',
        comment: lazy(() => import('../creativity/addCreativity/addCreativity'))
    },
    {
        path: '/layout/creativity/creativityfenlei',
        comment: lazy(() => import('../creativity/creativityfenlei/creativityfenlei'))
    },
    {
        path: '/layout/creativity/creativityList',
        comment: lazy(() => import('../creativity/creativityList/creativityList'))
    },
    {
        path: '/layout/cultural/cultural',
        comment: lazy(() => import('../cultural/cultural'))
    },
    {
        path: '/layout/hotelManagement/hotelManagement',
        comment: lazy(() => import('../hotelManagement/hotelManagement'))
    },
    {
        path: '/layout/system/system',
        comment: lazy(() => import('../system/system'))
    },
    {
        path: '/layout/drawback/drawback',
        comment: lazy(() => import('../drawback/drawback'))
    },
]
export default Routes

export const items = [
    {
        path: '/layout/creativity/creativityfenlei',
        item: [
            { label: '文创管理', key: '/layout/creativity' },
            { label: '文创分类', key: '/layout/creativity/creativityfenlei' }
        ]
    },
    {
        path: '/layout/creativity/addCreativity',
        item: [
            { label: '文创管理', key: '/layout/creativity' },
            { label: '文创新建', key: '/layout/creativity/addCreativity' }
        ]
    },
    {
        path: '/layout/creativity/creativityList',
        item: [
            { label: '文创管理', key: '/layout/creativity' },
            { label: '文创列表', key: '/layout/creativity/creativityList' }
        ]
    },
    {
        path: '/layout/ticket/category',
        item: [
            { label: '票务管理', key: '/layout/ticket' },
            { label: '门票分类', key: '/layout/ticket/category' }
        ]
    },
    {
        path: '/layout/sellticket/sellticket',
        item: [
            { label: '售票管理', key: '/sellticket/sellticket' }
        ]
    },
    {
        path: '/layout/travel/travel',
        item: [
            { label: '游记管理', key: '/travel/travel' }
        ]
    },
    {
        path: '/layout/wineshop/wineshop',
        item: [
            { label: '售票管理', key: '/wineshop/wineshop' }
        ]
    },
    {
        path: '/layout/wineshopTicket/wineshopTicket',
        item: [
            { label: '票务统计', key: '/wineshopTicket/wineshopTicket' }
        ]
    },
    {
        path: '/layout/system/system',
        item: [
            { label: '系统配置', key: '/system/system' }
        ]
    },
    {
        path: '/layout/drawback/drawback',
        item: [
            { label: '退款审核', key: '/drawback/drawback' }
        ]
    },
    {
        path: '/layout/statistics/statistics',
        item: [
            { label: '票务统计', key: '/layout/statistics/statistics' }
        ]
    },
    {
        path: '/layout/cultural/cultural',
        item: [
            { label: '文创统计', key: '/layout/cultural/cultural' }
        ]
    },
    {
        path: '/layout/ticket/addTicket',
        item: [
            { label: '票务管理', key: '/layout/ticket' },
            { label: '门票新建', key: '/layout/ticket/addTicket' }
        ]
    },
    {
        path: '/layout/ticket/list',
        item: [
            { label: '票务管理', key: '/layout/ticket' },
            { label: '门票列表', key: '/layout/ticket/list' }
        ]
    },
    {
        path: '/layout/hotelManagement/hotelManagement',
        item: [
            { label: '酒店管理', key: '/hotelManagement/hotelManagement' },
        ]
    },
    {
        path: '/layout/subscribe/appointment',
        item: [
            { label: '预约管理', key: '/layout/subscribe' },
            { label: '预约列表', key: '/layout/subscribe/appointment' }
        ]
    },
    {
        path: '/layout/subscribe/reservationsetting',
        item: [
            { label: '预约管理', key: '/layout/subscribe' },
            { label: '预约次数', key: '/layout/subscribe/appointment' }
        ]
    },
    
]