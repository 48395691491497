import React,{useEffect,useState,Suspense} from 'react'
import Siderbars from './component/siderbar/siderbar'
import Topheader from './component/topheader/topheader'
// import Error from '../../pages/error/error'
import { Layout,Spin } from 'antd';
import Routes,{items}from './Route'
import {Route,Switch,withRouter,Redirect} from 'react-router-dom'
const {  Content } = Layout;

function Parklayout(props) {
  const [headerdata,setdata]=useState({
    path:'/layout/sellticket/sellticket',
    item:[
      { label: '售票管理', key: '/layout/sellticket/sellticket' }
    ]
   })
 
  useEffect(()=>{
    props.history.listen(location => {
       let datas = items.find((items)=>{
        return items.path==location.pathname
      })
      setdata(datas)
  })
  },[])
  
  return (
    <>
      <Layout
      style={{
        minHeight: '100vh',
      }}
    >
        <Siderbars ></Siderbars>
        <Layout className="site-layout">
         <Topheader data={headerdata}></Topheader>
          <Content
            style={{
              margin: '0 16px',
            }}
          >
            <div
              className="site-layout-background"
              style={{
                padding: 24,
                minHeight: 360,
              }}
            >
              <Switch>
                {
                  Routes.map((item,index)=>{
                    let {comment:Comment}= item
                    return  <Route key={index} path={item.path} render={()=>{
                      return <Suspense fallback={<div style={{textAlign: 'center',margin:'300px 0'}}><Spin tip="加载中···" size="large"></Spin></div>}>
                        <Comment></Comment>
                      </Suspense>
                    }
                  }></Route>
                  })
                }
                <Redirect to='/layout/sellticket/sellticket'/>
              </Switch>
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  )
}
export default withRouter(Parklayout)
