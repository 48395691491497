import Router from '../src/router/index.jsx'
// import {Provider} from 'react-redux'
// import store from './pages/redux/store'

function App() {
  return (
    <>
        <Router></Router>
    </>
  );
}

export default App;
