import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
// import {connect} from 'react-redux'
import logo from "../../../../images/logo.png";
import wlogo from "../../../../images/user.png";
import { getInfo } from "../../../../untils/auth";
import {
  SolutionOutlined,
  DotChartOutlined,
  SlackOutlined,
  ShareAltOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
  AccountBookOutlined,
  CalendarOutlined
} from "@ant-design/icons";
import { Menu, Layout } from "antd";
const { Sider } = Layout;

const Siderbars = withRouter(function Siderbars(props) {
  const [collapsed, setCollapsed] = useState(false);
  const [items, setitems] = useState([]);
  function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label,
    };
  }
  useEffect(() => {
    if (getInfo().is_admin == "T") {
      setitems([
        getItem("售票管理", "/layout/sellticket/sellticket", <UserOutlined />),
        getItem(
          "酒店管理",
          "/layout/hotelManagement/hotelManagement",
          <ShareAltOutlined />
        ),
        getItem("预约管理", "/layout/subscribe",<CalendarOutlined />, [
          getItem("预约列表", "/layout/subscribe/appointment"),
          getItem("预约次数", "/layout/subscribe/reservationsetting"),
        ]),
        getItem("退票审核", "/layout/drawback/drawback", <TeamOutlined />),
        getItem("游记管理", "/layout/travel/travel", <SolutionOutlined />),
        getItem("票务管理", "/layout/ticket", <PieChartOutlined />, [
          getItem("门票列表", "/layout/ticket/list"),
          getItem("门票分类", "/layout/ticket/category"),
          getItem("门票新建", "/layout/ticket/addTicket"),
        ]),
        getItem(
          "文创管理",
          "/layout/creativity/creativity",
          <SlackOutlined />,
          [
            getItem("文创列表", "/layout/creativity/creativityList"),
            getItem("文创分类", "/layout/creativity/creativityfenlei"),
            getItem("文创新建", "/layout/creativity/addCreativity"),
          ]
        ),
        getItem(
          "票务统计",
          "/layout/statistics/statistics",
          <AccountBookOutlined />
        ),
        getItem("文创统计", "/layout/cultural/cultural", <DotChartOutlined />),
      ]);
    } else {
      setitems([
        getItem("售票管理", "/layout/wineshop/wineshop", <UserOutlined />),
        getItem(
          "票务统计",
          "/layout/wineshopTicket/wineshopTicket",
          <UserOutlined />
        ),
      ]);
    }
  }, []);
  const onClick = (e) => {
    props.history.push({
      pathname: e.key,
    });
  };
  return (
    <>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        {!collapsed && (
          <div
            style={{
              fontSize: "20px",
              color: "white",
              fontWeight: "bold",
              textAlign: "center",
              marginTop: "20px",
              marginBottom: "10px",
            }}
          >
            {getInfo().remake ? getInfo().remake : "青岛电影博物馆"}
          </div>
        )}
        {collapsed && (
          <div style={{ textAlign: "center", paddingTop: "15px" }}>
            <img
              src={getInfo().is_admin == "T" ? logo : wlogo}
              alt="logo"
              style={{ width: "40px", height: "40px" }}
            ></img>
          </div>
        )}
        <Menu
          theme="dark"
          defaultSelectedKeys={["/layout/sellticket/sellticket"]}
          mode="inline"
          items={items}
          onClick={onClick}
        />
      </Sider>
    </>
  );
});

// export default connect((state)=>{
//   return {
//     info:state.info
//   }
// })(Siderbars)
export default Siderbars;
