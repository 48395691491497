import React,{useCallback, useState} from 'react'
import loginCss from './login.module.css'
import { UserOutlined ,LockOutlined} from '@ant-design/icons';
import { Col, Row,Input ,Space,Button,message,Radio} from 'antd';
import _ from '../../untils/request'
import {setToken,setInfo} from '../../untils/auth'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
const Login=withRouter(function Login(props) {
  const [username,setname]=useState('')
  const [password,setpossword]=useState('')
  const [type,settype]=useState('')
  const [messageApi, contextHolder] = message.useMessage();
  let title={
    color:'white',
    fontSize:'24px',
    fontWeight:'bold'
  }
  const login=async ()=>{
    try {
      let data={
        username,
        password,
        type
      }
      if(type==''){
        messageApi.open({
          type: 'error',
          content:'请选择登录类型',
        });
        return
      }
      // 
      let reault =await _.post('/api/v1/login/login',data)
      
      if(reault.status==200){
        messageApi.open({
          type: 'success',
          content: reault.data.message,
        });
        setInfo(reault.data.data)
        // props.info({
        //   is_admin:reault.data.data.is_admin
        // })
        setToken(reault.data.access_token)
        if(reault.data.data.is_admin=='T'){
          setTimeout(() => {
            props.history.push({
              pathname:'/layout/',
            })
          }, 1000);
        }else{
          setTimeout(() => {
            props.history.push({
              pathname:'/layout/wineshop/wineshop',
            })
          }, 1000);
        }
       
      }else{
        messageApi.open({
          type: 'error',
          content:reault.data.message,
        });
      }
      

    } catch (error) {
      messageApi.open({
        type: 'error',
        content:error,
      });
    }
    }
  const nameChange=(event)=>{
    setname(event.target.value)
  }
  const passwordChange =(event)=>{
    setpossword(event.target.value)
  }
  const onChange=(event)=>{
    settype(event.target.value)
  }
  return (
    <div className={loginCss.content}>
      {contextHolder}
      <div className={loginCss.box}>
        <Row>
          <Col span={11}>
            <div style={{display: 'flex',flexDirection:'column',alignItems: 'center',justifyContent:'center',height:'90%'}}>
              <img src="https://images.maxdata.cc/museum/images/23-03-15/5b20cef0fb5e01fbf117761d88149135641126a9ce731.jpg" alt='大厦' style={{width:'382px',height:'270px',color:'white'}}></img>
              {/* <div style={title}>电影博物馆票务系统</div> */}
            </div>
          </Col>
          <Col span={2} className={loginCss.line}></Col>
          <Col span={11} >
            <Space direction="vertical" size='middle' >
              <div className={loginCss.login}>欢 迎 回 来</div>
              <Input value={username} size="large"  onChange={nameChange} prefix={<UserOutlined style={{color:'white'}}/>} style={{backgroundColor:'transparent',color:'white'}}/>
              <Input.Password value={password} size="large" onChange={passwordChange} prefix={<LockOutlined style={{color:'white'}}/>} style={{backgroundColor:'transparent',color:'white'}}/>
              <Radio.Group onChange={onChange} value={type}>
                <Radio value={'M'}>青岛电影博物馆</Radio>
                <Radio value={'H'}>酒店</Radio>
              </Radio.Group>
              <Button type="primary"  block onClick={login}>登录</Button>
            </Space>
          </Col>
        </Row>
      </div>
    </div>
  )
})
// export default connect(null,{
//   info(data){
//     return {
//       type:'setinfo',
//       payload:data
//     }
//   }
// })(Login)
export default Login
