import React from 'react';
import ReactDOM from 'react-dom/client';
import './App.css';
import App from './App';
import {Provider} from 'react-redux'
const root = ReactDOM.createRoot(document.getElementById('root'));
// import store,{persistor} from './pages/redux/store'
import store from './pages/redux/store'
// import {PersistGate} from 'redux-persist/lib/integration/react';
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    {/* <PersistGate  persistor={persistor}> */}
        <App />
    {/* </PersistGate> */}
  </Provider>
   
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
