import React,{useEffect,useState} from 'react'
import {DownOutlined} from '@ant-design/icons';
import { Layout,Avatar,Dropdown ,Space,Breadcrumb} from 'antd';
import {removeToken,removeInfo,getInfo} from '../../../../untils/auth.js'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
const { Header} = Layout;
const Topheader= withRouter(function Topheader(props) {
  const [item,setitem]=useState([])
  useEffect(()=>{
    
    setitem(props.data.item)
  },[props.data])
  const items = [
    {
      label: <span onClick={()=>{
        removeToken()
        removeInfo()
        // props.lognOut()
        props.history.push({
          pathname:'/',
        })
      }}>退出登录</span>,
      key: '1',
    },
  ];
  return (
   <>
    
    <Header
        style={{
            padding: 0,
            background:'white'
        }}
    >
      <div style={{display:'flex',justifyContent:'space-between',alignItems: 'center',padding:'0 20px'}}>
        <div>
          <Breadcrumb>
            { item.map((i)=>{
              return <Breadcrumb.Item key={i.key}>{i.label}</Breadcrumb.Item>
            })}
            
          </Breadcrumb>
        </div>
       
        <div>
          <Avatar size={35} src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" style={{marginRight:'15px'}}/>
          <Dropdown
            menu={{
              items,
            }}
            trigger={['click']}
            >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                {/* {
                  getInfo()().is_admin=="T"?'超级管理员':'酒店管理员'
                } */}
                {
                  getInfo().is_admin=="T"?'超级管理员':'酒店管理员'
                }
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
        </div>
        
      </div>
    </Header>
   </>
  )
})
// export default connect((state)=>{
//   return {
//     info:state.info
//   }
// },{
//   lognOut(){
//     return {
//       type:'lognOut',
//     }
//   }
// })(Topheader)
export default Topheader